import { FunctionComponent, useState, useEffect } from "react";
import { AppBar, Box, FormControl, Input, InputAdornment, InputLabel } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import { UserType } from "../../App";

type Props = {
  organizationName: string;
};

const Header: FunctionComponent<Props> = ({ organizationName }) => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [userType, setUserType] = useState<UserType>(UserType.GUEST);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLink = (page: string) => {
    switch (page) {
      case "Vehicles":
        navigate("/Vehicles");
        break;
      case "Units":
        navigate("/Units");
        break;
      case "Softwares":
        navigate("/Software");
        break;
      case "Activate/Register vehicle":
        navigate("/Activate");
        break;
      case "Admin":
        navigate("/Admin");
        break
      case "Logout":
        SignOut();
        break;
    }
    setAnchorElNav(null);
  };

  // MSAL
  const { accounts, instance } = useMsal();

  // I18n
  const { t, i18n } = useTranslation();

  // Routing
  const location = useLocation();
  const navigate = useNavigate();

  /**
   * @brief Sign an user out
   * @returns void
   */
  const SignOut = async () => {
    const logoutRequest = {
      account: accounts[0],
      mainWindowRedirectUri: "/",
    };
    await instance.logoutPopup(logoutRequest);
  };

  useEffect(() => {

    const tokenRequest = {
      account: accounts[0], // This is an example - Select account based on your app's requirements
      scopes: ["https://standbyb2c.onmicrosoft.com/d731717e-c40d-4345-bc1b-263d40e3722e/DMP.ALL"]
    }

    // Acquire an access token
    instance.acquireTokenSilent(tokenRequest).then((response) => {
      setUserType(response.account.idTokenClaims!["extension_UserEnum"] as UserType);
    }).catch(async (e) => {
      console.log(e)
    });

  }, [accounts, instance]);

  /**
   * @brief Get path depending on user type
   * @returns void
   */
  const getPath = () => {

    if (userType === UserType.ENDCUSTOMER) {
      return "Activate";
    }
    if (userType === UserType.UPFITTER) {
      return "Register";
    }
    return "Activate";
  };

  /**
   * @brief User email
   * @returns string
   */
  const GetUsername = () =>
    accounts[0] === undefined ? "" : accounts[0].username;



  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#fff",
      }}
    >
      <AppBar
        elevation={0}
        position="static"
        sx={{ pl: 2, pr: 2, backgroundColor: "rgb(0 119 200)" }}
      >
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              mb: 0.5,
              display: { xs: "none", md: "flex" },
              fontWeight: 700,
              color: "#fff",
              textDecoration: "none",
            }}
          >
            Device Management Platform
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {userType ===
                UserType.STANDBYUSER && (
                  <>
                    <MenuItem
                      onClick={() => handleLink("Vehicles")}
                      sx={{ my: 2, display: "block" }}
                    >
                      {t("vehicles")}
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleLink("Units")}
                      sx={{ my: 2, display: "block" }}
                    >
                      {t("units")}
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleLink("Softwares")}
                      sx={{ my: 2, display: "block" }}
                    >
                      {t("softwares")}
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleLink("Admin")}
                      sx={{ my: 2, display: 'block' }}
                    >
                      Admin
                    </MenuItem>
                  </>
                )}
              {userType !==
                UserType.GUEST && (
                  <MenuItem
                    onClick={() => handleLink("Activate/Register vehicle")}
                    sx={{ my: 2, display: "block" }}
                  >
                    {t("activateVehicle")}
                  </MenuItem>
                )}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "#fff",
              textDecoration: "none",
            }}
          >
            DMP
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {userType ===
              UserType.STANDBYUSER && (
                <>
                  <Button
                    onClick={() => handleLink("Vehicles")}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {t("vehicles")}
                  </Button>
                  <Button
                    onClick={() => handleLink("Units")}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {t("units")}
                  </Button>
                  <Button
                    onClick={() => handleLink("Softwares")}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {t("softwares")}
                  </Button>
                  <Button
                    onClick={() => handleLink("Admin")}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    Admin
                  </Button>
                </>
              )}
            {userType !==
              UserType.GUEST && (
                <Button
                  onClick={() => handleLink("Activate/Register vehicle")}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {t("activateVehicle")}
                </Button>
              )}
          </Box>
          {userType ===
            UserType.STANDBYUSER && (
              <FormControl
                sx={{
                  width: { md: "40ch", xs: "20ch" },
                  mb: 2.5,
                  mr: 5,
                  display: "none",
                }}
                variant="standard"
              >
                <InputLabel
                  htmlFor="standard-adornment-search"
                  sx={{ color: "white" }}
                >
                  Sök efter fordon och enheter
                </InputLabel>
                <Input
                  id="standard-adornment-search"
                  type="text"
                  sx={{ borderBottom: "solid 1px #fff", color: "white" }}
                  disableUnderline
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility">
                        <SearchIcon sx={{ color: "white" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            )}
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ p: 0, color: "#fff" }}
              >
                <AccountCircleIcon fontSize="large" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={() => i18n.changeLanguage("en")}>
                <Typography textAlign="center">Engelska</Typography>
              </MenuItem>
              <MenuItem onClick={() => i18n.changeLanguage("sv")}>
                <Typography textAlign="center">Svenska</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleLink("Logout")}>
                <Typography textAlign="center">{t("signOut")}</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;

import { createTheme } from "@mui/material";
//import shadows, { Shadows } from '@mui/material/styles/shadows';

export const primaryTheme = createTheme({
  //shadows: shadows.map(() => 'none') as Shadows,
  palette: {
    primary: {
      main: "rgb(0 119 200)",
    },
    secondary: {
      main: "#f0f0f0",
    },
  },
  typography: {
    fontFamily: "Neo Sans W01",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {
    // @ts-ignore custom component
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&:before": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  },
});

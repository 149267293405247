import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      sv: {
        translation: {
          signedInAs: "Inloggad som",
          signOut: "Logga ut",
          vehicles: "Fordon",
          units: "Enheter",
          parentUnit: "Kopplad artikel",
          unitHistory: "Enhetshistorik",
          softwares: "Mjukvaror",
          activateVehicle: "Aktivera fordon",
          registerVehicle: "Registrera fordon",
          organization: "Organisation",
          vehicleBrand: "Fordonsmärke",
          vehicleModel: "Fordonsmodell",
          activationDate: "Aktiveringsdatum",
          link: "Länk",
          viewVehicle: "Visa fordon",
          licencePlate: "Registreringsnummer/fordons-ID",
          noResult: "Hittade inga resultat",
          sort: "Sortera",
          sortFor: "Sortera efter",
          nextPage: "Nästa sida",
          previousPage: "Föregående sida",
          rowsPerPage: "Rader per sida:",
          of: "av",
          search: "Sök",
          downloadCsv: "Ladda ned CSV",
          print: "Skriv ut",
          viewColumns: "Visa Kolumner",
          filterTable: "Filtrera tabell",
          all: "ALLA",
          filters: "FILTER",
          reset: "ÅTERSTÄLL",
          rowsSelected: "Rad(er) vald(a)",
          delete: "Ta bort",
          deleteSelectedRows: "Ta bort valda rader",
          device: "Enhet",
          serialnumber: "Serienummer",
          partnumber: "Artikelnummer",
          goBack: "Tillbaka",
          unitName: "Enhetens namn",
          vehicle: "Fordon",
          devices: "Enheter",
          protocol: "Protokoll",
          date: "Datum",
          unitEvent: "Enhetshändelse",
          addCustomEvent: "Lägg till egen händelse",
          versionNotFound: "Versionen hittades ej!",
          viewProtocol: "Visa protokoll",
          softwarePackageVersion: "Mjukvarupaket version",
          vehicletype: "Fordonstyp",
          vehiclebuilder: "Fordonsbyggare",
          owner: "Ägare",
          createdBy: "Skapad av",
          station: "Station",
          contactperson: "Kontaktperson",
          vehicleHistory: "Fordonshistorik",
          latestInventory: "Senast inventeringsdatum",
          softwareVersions: "Mjukvaruversioner",
          vehicleEvent: "Fordonshändelse",
          installed: "Installerad",
          activated: "Aktiverad",
          customEvent: "Egen händelse",
          allLabel: "Alla",
          vehilcetype: "Fordonstyp",
          step1: "Välj aktiveringsmetod",
          step2: "Skanna QR-kod eller ladda upp",
          step3: "Uppdatera eller skapa fordon",
          step4: "Visa fordonsinformation",
          step5: "Aktivera och bekräfta",
          step6: "Registrera och bekräfta",
          unauthorized: "Du har ej behörighet att visa den här resursen!",
          scanqr: "Skanna QR-kod",
          uploadfile: "Ladda upp .zipfil",
          backtopre: "Gå tillbaka till föregående steg",
          skip: "Skippa detta steg",
          missinginfo: "Inventeringsfilen innehåller inget VIN-nummer!",
          nozip: "Filen du försöker ladda upp verkar inte vara en .zip!",
          drophere: "Släpp filen här...",
          or: "Eller",
          clickhere:
            "Klicka på den här boxen eller släpp din inventeringsfil här!",
          vehiclenotfound: "Ditt fordon hittades",
          not: "inte",
          notfoundcont: "i vår databas, vänligen fyll i några uppgifter...",
          vehicleFound: "Ditt fordon hittades i vår databas!",
          notfound: "Ditt fordon hittades inte i vår databas!",
          updateVehicle: "Uppdatera fordon",
          inputlicenseplate: "Vänligen ange registreringsnummer",
          findVehicle: "Hitta mitt fordon",
          plate: "Registreringsnummer",
          vehicleid: "Eller ditt fordons-ID (eller både)",
          nextstep: "Nästa steg",
          otherIdentifier: "Annat ID",
          clear: "Rensa",
          basicinfo: "Information om ditt fordon",
          vehicleident: "Fordons-ID",
          vehiclefeatures: "Fordonsegenskaper",
          enduser: "Slutanvändare",
          activateandcfm: "Aktivera och bekräfta",
          dup: "Dubbla inventeringsfiler i molnet",
          saved: "Inventering sparad till databas",
          another: "Aktivera ett annat fordon",
          number: "Ange denna aktiveringskod på din enhet",
          verify: "Verifiera",
          vehicleID: "Fordons-ID",
          tested: "Testad",
          navigation: "Visa endast bilar med navigation",
          pleasewait: "Vänligen vänta... (Hämtar mer än 120,000 enheter)",
          step0: "Ange Registreringnummer",
          fetchingUnits: "Hämtar enheter...",
          loadingPage: "Laddar sida...",
          fetchingVehicles: "Hämtar fordon...",
          withNav: "Med navigation",
          showAll: "Visa alla fordon",
          off: "Av",
          registered: "Registrerad",
          on: "På",
          searchVehicle: "Sök fordon..",
          softwarestring1: "Lista av mjukvaror som ",
          configuredyet: "är konfigurerade ännu",
          softwarestring2:
            "Dessa mjukvaror har blivit skapad av DMP och är redan kopplad till en enhet.",
          unkownsw: "Okänd mjukvara",
          updatesw: "Uppdatera alla mjukvaror",
          softwarename: "Mjukvarunamn",
          findunits: "Hitta enheter",
          addunit: "Lägg till enhet",
          addunittov: "Lägg till enhet till fordon",
          vid: "Fordons-ID",
          reprogrammed: "Omprogrammerad"
        },
      },
      en: {
        translation: {
          parentUnit: "Top unit",
          clear: "Clear",
          registered: "Registered",
          signedInAs: "Signed in as",
          notfound: "Your vehicle was not found in our database!",
          signOut: "Sign Out",
          otherIdentifier: "Other ID",
          vehicles: "Vehicles",
          vehiclefeatures: "Vehicle features",
          units: "Units",
          tested: "Tested",
          unauthorized: "You don't have access to this resource!",
          findVehicle: "Find my vehicle",
          step6: "Register and confirm",
          unitHistory: "Unithistory",
          softwares: "Softwares",
          softwarestring1: "List of softwares which are ",
          activateVehicle: "Activate Vehicle",
          registerVehicle: "Register Vehicle",
          licencePlate: "Licence plate/vehicle-ID",
          organization: "Organization",
          vehicleBrand: "Vehicle Brand",
          vehicleModel: "Vehicle Model",
          activationDate: "Activation date",
          link: "Link",
          viewVehicle: "View Vehicle",
          noResult: "Sorry, no matching records found",
          sort: "Sort",
          plate: "License plate",
          vehicleID: "Vehicle-ID",
          sortFor: "Sort for",
          nextPage: "Next Page",
          previousPage: "Previous Page",
          rowsPerPage: "Rows per page:",
          of: "of",
          search: "Search",
          downloadCsv: "Download CSV",
          print: "Print",
          viewColumns: "View Columns",
          filterTable: "Filter Table",
          allLabel: "ALL",
          filters: "FILTERS",
          reset: "RESET",
          rowsSelected: "Row(s) selected",
          delete: "Delete",
          deleteSelectedRows: "Delete selected rows",
          device: "Device",
          serialnumber: "Serial number",
          partnumber: "Part number",
          goBack: "Go Back",
          unitName: "Unitname",
          vehicle: "Vehicle",
          devices: "Devices",
          protocol: "Protocol",
          date: "Date",
          unitEvent: "UnitEvent",
          addCustomEvent: "Add Custom Event",
          versionNotFound: "Version Not Found!",
          viewProtocol: "View Protocol",
          softwarePackageVersion: "Software Package Version",
          vehilcetype: "Vehicletype",
          vehiclebuilder: "Vehiclebuilder",
          pleasewait: "Please wait..(fetching more than 120,000 units)",
          navigation: "Only show vehicles with navigation",
          owner: "Owner",
          station: "Station",
          contactperson: "Contact Person",
          vehicleHistory: "Vehicle History",
          latestInventory: "Latest inventorydate",
          softwareVersions: "Software versions",
          vehicleEvent: "Vehicle Event",
          installed: "Installed",
          activated: "Activated",
          customEvent: "Custom Event",
          step1: "Select activation method",
          step2: "Scan or upload",
          step3: "Update or create vehicle",
          step4: "View vehicle information",
          step5: "Activate and confirm",
          step0: "Enter Licenseplatenumber",
          scanqr: "Scan QR-code",
          uploadfile: "Upload .zipfile",
          backtopre: "Go Back To Previous Step",
          skip: "Skip this step",
          vid: "Vehicle-ID",
          missinginfo:
            "The inventoryfile is missing VIN number, which is required!",
          nozip: "The file you try to upload doesnt seem to be a .zip file",
          drophere: "Drop the file here ...",
          or: "Or",
          clickhere:
            "Click on this box or drag and drop your inventrory file here",
          vehiclenotfound: "Your vehicle was ",
          not: "not",
          configuredyet: "configured yet",
          notfoundcont:
            "found in our database. Please supply us with more information..",
          vehicleFound: "Your vehicle was found in our database!",
          updateVehicle: "Update Vehicle",
          inputlicenseplate: "Please enter your vehicle license plate number",
          vehicleid: "Or your vehicle ID (or both)",
          nextstep: "Next step",
          basicinfo: "Basic information about your vehicle",
          vehicleident: "Vehicle-ID",
          enduser: "Enduser",
          activateandcfm: "Activate and confirm",
          dup: "Duplicate inventory files",
          saved: "Inventory saved to database",
          another: "Activate another vehicle",
          number: "Enter this activationcode on your device",
          verify: "Verify",
          fetchingUnits: "Fetching units...",
          loadingPage: "Loading page...",
          fetchingVehicles: "Fetching vehicles...",
          withNav: "With navigation",
          showAll: "Show all vehicles",
          off: "Off",
          on: "On",
          searchVehicle: "Search vehicles..",
          softwarestring2:
            "These softwares has been created by DMP and are already connected to an unit.",
          unkownsw: "Unkown software",
          updatesw: "Update all softwares",
          softwarename: "Software name",
          findunits: "Find units",
          addunit: "Add unit",
          addunittov: "Add unit to vehicle",
          createdBy: "Created by",
          reprogrammed: "Reprogrammed"
        },
      },
    },
  });

export default i18n;

import React, { Suspense, FunctionComponent, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import {
  MsalAuthenticationTemplate,
  useMsal,
} from "@azure/msal-react";
import { useTranslation } from 'react-i18next';
import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { primaryTheme } from "./theme/primary.theme";
import ErrorBoundary from "./components/Common/ErrorBoundary";
import Header from "./components/Common/Header.Component";
import FullpageSpinner from "./components/Common/FullpageSpinner";
import Footer from "./components/Common/Footer.Component";

export enum UserType {
  UNDEFINED = -1,
  GUEST = 0,
  STANDBYUSER = 1,
  UPFITTER = 2,
  ENDCUSTOMER = 3,
}

/* Containers */
const VehiclesContainer = React.lazy(() => import('./containers/Vehicles.Container'));
const UnitsContainer = React.lazy(() => import('./containers/Units.Container'));
const VehicleDetailContainer = React.lazy(() => import('./containers/VehicleDetail.Container'));
const SoftwareContainer = React.lazy(() => import('./containers/Software.Container'));
const AdminContainer = React.lazy(() => import('./containers/Admin.Container'));
const UnitDetailContainer = React.lazy(() => import('./containers/UnitDetail.Container'));
const ActivateContainer = React.lazy(() => import('./containers/Activate.Container'));
const RegisterContainer = React.lazy(() => import('./containers/Register.Container'));
const AccessDeniedContainer = React.lazy(() => import('./containers/AccessDenied.Container'));

const App: FunctionComponent = () => {

  // I18n
  const { t } = useTranslation();

  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {

    const tokenRequest = {
      account: accounts[0], // This is an example - Select account based on your app's requirements
      scopes: ["https://standbyb2c.onmicrosoft.com/d731717e-c40d-4345-bc1b-263d40e3722e/DMP.ALL"]
    }

    // Acquire an access token
    instance.acquireTokenSilent(tokenRequest).then((response) => {
      // Call your API with the access token and return the data you need to save in state

      switch (response.account.idTokenClaims!["extension_UserEnum"] as UserType) {
        case UserType.GUEST:
          window.location.href = ("/AccessDenied");
          break;
        case UserType.UPFITTER:
          window.location.href = ("/Activate");
          break;
        case UserType.ENDCUSTOMER:
          window.location.href = ("/Register");
          break;
      }

    }).catch(async (e) => {
      console.log(e)
    });

  }, [accounts, instance]);

  return (
    <Box sx={{
      minHeight: "100vh",
      margin: 0,
      display: "grid",
      gridTemplateRows: "auto 1fr auto",
    }}>
      <ThemeProvider theme={primaryTheme}>
        <CssBaseline />
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <ErrorBoundary>
            <Router>
              <Header organizationName="" />
              <Suspense fallback={<FullpageSpinner text={t('loadingPage')} />}>
                <Routes>
                  <Route path="/" element={<VehiclesContainer />} />
                  <Route path="/Admin" element={<AdminContainer />} />
                  <Route path="/AccessDenied" element={<AccessDeniedContainer />} />
                  <Route path="/Vehicles" element={<VehiclesContainer />} />
                  <Route path="/Units" element={<UnitsContainer />} />
                  <Route path="/Software" element={<SoftwareContainer />} />
                  <Route path="/Activate" element={<ActivateContainer />} />
                  <Route path="/Register" element={<RegisterContainer />} />
                  <Route path="Unit">
                    <Route
                      path=":unitId"
                      element={<UnitDetailContainer />}
                    />
                  </Route>
                  <Route path="Vehicle">
                    <Route
                      path=":vehicleId"
                      element={<VehicleDetailContainer />}
                    />
                  </Route>
                </Routes>
              </Suspense>
              <Footer />
            </Router>
          </ErrorBoundary>
        </MsalAuthenticationTemplate>
      </ThemeProvider>
    </Box >
  );
};

export default App;

import { FunctionComponent } from "react";
import { Box } from "@mui/material";
import { PropagateLoader } from "react-spinners";

type Props = {
  text: string;
};
const FullpageSpinner: FunctionComponent<Props> = ({ text }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: { md: "85vh" },
        mt: { md: 0, xs: 8 },
        flexDirection: "column",
      }}
    >
      <PropagateLoader size={30} />
      <Box sx={{ mt: 6, fontWeight: "bold", textAlign: "center" }}>{text}</Box>
    </Box>
  );
};

export default FullpageSpinner;

import { createRoot } from "react-dom/client";
import {
  applyPolyfills,
  defineCustomElements,
} from "xml-viewer-component/dist/loader";
import App from "./App";
import { msalConfig } from "./config/auth.config";
import { InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import {
  MsalProvider,
} from "@azure/msal-react";
import "./index.css";
import "./i18n/i18n";

// Authentication 
const msalInstance = new PublicClientApplication(msalConfig as any);

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<MsalProvider instance={msalInstance}><App /></MsalProvider>);

// This polyfill enables XMLviewer
applyPolyfills().then(() => {
  defineCustomElements(window);
});

import { Component, ReactNode } from "react";
import { Paper, Box } from "@mui/material";
import ErrorIcon from "../../static/images/icons/cancel.png";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: new Error(),
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: new Error() };
  }

  public componentDidCatch(error: Error) {
    this.setState({
      error: error,
    });
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Paper sx={{ p: 4, margin: "0 auto", textAlign: "center" }}>
            <img src={ErrorIcon} />
            <h1 style={{ color: "#333" }}>Something went wrong..</h1>
            <p style={{ fontSize: 12 }}>{this.state.error.message}</p>
            <a href="/">Go back to home</a>
          </Paper>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

import { FunctionComponent, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { UserType } from "../../App";



const Footer: FunctionComponent = () => {

  // MSAL
  const { accounts, instance } = useMsal();
  const [userType, setUserType] = useState<UserType>(UserType.GUEST);

  useEffect(() => {

    const tokenRequest = {
      account: accounts[0], // This is an example - Select account based on your app's requirements
      scopes: ["https://standbyb2c.onmicrosoft.com/d731717e-c40d-4345-bc1b-263d40e3722e/DMP.ALL"]
    }

    instance.acquireTokenSilent(tokenRequest).then((response) => {
      setUserType(response.account.idTokenClaims!["extension_UserEnum"] as UserType);
    }).catch(async (e) => {
      console.log(e)
    });

  }, [accounts, instance]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        bottom: 0,
        minHeight: 80,
        mt: { md: 0, xs: 4 },
      }}
    >
      <Box
        sx={{
          borderTop: "solid 1px #ccc",
          pt: 8,
          pb: 2,
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Box sx={{ width: "50%", textAlign: "center" }}>
          Device Management Platform - Copyright © 2023 Standby AB
          <Box sx={{ alignSelf: "center", pb: 4 }}>
            {userType === UserType.STANDBYUSER && (
              <Box>
                {process.env.REACT_APP_ENV === "production" && (
                  <span style={{ marginRight: 20, color: "red" }}>
                    (Environment: Production)
                  </span>
                )}
                {process.env.REACT_APP_ENV === "test" && (
                  <span style={{ marginRight: 20, color: "orange" }}>
                    (Environment: Test)
                  </span>
                )}
                {process.env.REACT_APP_ENV === "development" && (
                  <span style={{ marginRight: 20, color: "green" }}>
                    (Environment: Development)
                  </span>
                )}
                {process.env.REACT_APP_ENV === "local" && (
                  <span style={{ marginRight: 20, color: "darkgreen" }}>
                    (Environment: Local)
                  </span>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
